// 微环境气象监测
import DJZT from './sn-table-column-djzt';
const selfData = {
    loading: false,
    snTable: {

        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            columnsData: [{
                label: "监测时间",
                prop: "JCSJ",
                minWidth: 100
            }, {
                label: "设备名称",
                prop: "SBMC",
            }, {
                label: "温度(℃)",
                prop: "WD"
            }, {
                label: "湿度(%)",
                prop: "SD"
            }, {
                label: "风向",
                prop: "FX"
            }, {
                label: "风速(m/s)",
                prop: "FS"
            }, {
                label: "降雨量(mm)",
                prop: "YL"
            }, {
                label: "气压(hPa)",
                prop: "QY"
            }, {
                label: "太阳辐射(w/㎡)",
                prop: "TYFS"
            },
                DJZT
            ],
            // 操作列
            columnsBtn: {
                buttonData: []
            }
        },
    },
    snSearch: {
        inputData: [{
            label: '关键字',
            placeholder: "请输入关键字",
            value: 'keyword',
            operateType: 'search',
            isShow: true,
            default: ""
        }],
        dateData : [{
            isShow: true,
            label: '开始时间',
            clearable: true,
            placeholder: '开始时间',
            operateType: 'search',
            value: 'start',
        },{
            isShow: true,
            label: '结束时间',
            clearable: true,
            placeholder: '结束时间',
            operateType: 'search',
            value: 'end',
        }]
    },
    snButton: {
        buttonData: [
            {
                btnType: 'button',
                operateType: 'buttonExport',
                showMethod: 'showExportBtn',
                name: '导出',
                round: true,
                backColor: '#ffcc66',
                color: '#fff'
            }
        ]
    }
};
export default selfData;